import React, { useEffect, useState } from "react";
import { Page, Section, LoaderFullPage } from "../../components";
import { useStyles } from "../../components2";
import { CustomTable } from "../../components2/Tables2";
import { Plus } from "../../components2/Icons";
import { listPost } from "../../lib/ec2-api-cms2-lib";
import { adminPopupNotification } from "./settings";
import { createDefaultGroupCat } from "./createDefaultGroupCategory";
import { json2obj } from "../../lib/utils";
import { useCookies } from "react-cookie";

import PostModal from "./PostModal2";
import PopupNotiModal from "./PopupNotiModal";

export default function Posts(props) {
  const title = "Posts by Categories";
  const [isLoading, setIsLoading] = useState(true)
  const [tableItems, setTableItems] = useState([])
  const [post, setPost] = useState({});
  const [catList, setCatList] = useState([])

  /** @공지사항_테스트 */

  const notiTest = true
  const [notiPost, setNotiPost] = useState(null)
  const [visibleNoti, setVisibleNoti] = useState(false)
  const [cookies] = useCookies(["NotiIdBlackList"]);

  useEffect(() => {
    onload();
  }, [])

  const onload = async () => {
    try {
      setIsLoading(true);

      let res = await createDefaultGroupCat()
      if (res.err) throw Error(res.err.message)

      const cList = json2obj(res.result.Item.categoryList)
      setCatList(cList)

      /** @공지사항_테스트 */

      const cats = cList.filter(c => c.categoryName === adminPopupNotification.categoryName)
      if (cats.length === 1) {
        res = await listPost({ categoryId: cats[0].categoryId, start_time: Date.now() })
        if (res.err) throw Error(res.err.message)
        // alert(JSON.stringify(res.result.Items, null, 2))
        if (res.result.Items.length > 0) {
          const nPost = res.result.Items[0]
          const blackList = cookies.NotiIdBlackList || []
          const isNotiInBl = blackList.includes(nPost.postId)
          // console.log('in blackList?', isNotiInBl)
          if (!isNotiInBl) {
            setNotiPost(nPost)
            setVisibleNoti(true)
          }

        }
      }

    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false)
    }
  }

  const otherProps = {
    onload,
    post,
    // isEdit, setEditItem,
    catList,
    setIsLoading, isLoading,
    categoryList: catList
  }

  return (
    <Page stretch title={title}>

      {
        catList.map((cat, i) => {
          return <PostsByCategories key={i} {...props} {...otherProps} category={cat} />
        })
      }

      {notiTest &&
        <PopupNotiModal
          visible={visibleNoti}
          setIsVisible={setVisibleNoti}
          post={notiPost}
        />
      }

      {isLoading && <LoaderFullPage />}

      {/*  */}
    </Page>

  )
}

const tableHeader = [
  // { name: "selected", displayName: "-", type: "checkbox", keyName: "postId" },
  { name: "postId", displayName: "Post ID", selectKey: true },
  { name: "title", displayName: "Title", sorting: true },
  // { name: "categoryName", displayName: "Category" },
  { name: "excerpt", displayName: "Summary" },
  { name: "publish", displayName: "Publish" },
  { name: "lastModified", displayName: "LT" },
]

function PostsByCategories(props) {
  const { category, categoryList, setIsLoading } = props
  const { gStyle } = useStyles()

  const [isEdit, setIsEdit] = useState(false)
  const [editItem, setEditItem] = useState({})
  const [posts, setPosts] = useState([])
  const [visibleModal, setVisibleModal] = useState(false)

  useEffect(() => {
    onload()
  }, [category])

  const onload = async () => {
    try {
      setIsLoading(true)
      let res = await listPost(category)
      if (res.err) throw Error(res.err.message)
      setPosts(res.result.Items)
    } catch (e) {
      alert(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const addPost = () => {
    if(category.categoryType ==="LandingPage") {
      props.history.push(`/cms/landing-page`)
    } else {
      setIsEdit(false)
      setEditItem({})
      setVisibleModal(true)
    }
  }

  const onSelectItem = (item) => {
    const cats = categoryList.filter(cat=>cat.categoryId===item.categoryId)
    let basePath = "/cms/post/view"

    // 
    // LandingPage 는 별도로 분기
    // 
    if(cats[0].categoryType==="LandingPage") {
      basePath = `/cms/landing-page`
    }
    props.history.push( basePath + `?categoryId=${item.categoryId}&postId=${item.postId}`)

  }

  const styles = {
    header: {
      margin: 3,
      display: "flex", alignItems: "center", justifyContent: "space-between"
    },
    groupHeader: {
      display: "flex", alignItems: "center"
    },
    groupHeaderItem: {
      ...gStyle.subtitle,
      marginRight: 10,
    }
  }

  return (
    <Section bgColor="#fff">
      <div style={styles.header}>
        <h3 style={styles.groupHeaderItem}>
          <Plus onClick={addPost} />
          &nbsp;&nbsp;{category.categoryName}
          &nbsp;-&nbsp;{category.categoryType}
        </h3>
      </div>
      <hr />

      <CustomTable
        tableHeader={tableHeader}
        rowItems={posts}
        setRowItems={setPosts}
        onClickItem={onSelectItem}
      />

      {/* {
        (visibleModal && !isLoading) && */}
      <PostModal
        {...props}
        isEdit={isEdit}
        editItem={editItem}
        post={editItem}
        visible={visibleModal}
        category={category}
        setIsVisible={setVisibleModal}
      />
      {/* } */}
    </Section>
  )
}