import React, { useState } from "react";
import { Page, Section } from "../../components";
import { parseItemOptions, getItemOption } from "../../lib-pos";
import { useStyles, LoaderFullPage } from "../../components2";
import { QRCodeSVG } from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SearchStore from "./components/SearchStore";
import { debug, authDebug } from "../../settings";

export default function QRCodes(props) {
  const title = "테이블 QR Code 생성"
  const { gStyle, colors } = useStyles()

  const [isLoading, setIsLoading] = useState(false);
  const [shopInfo, setShopInfo] = useState({})
  const [qrcodeUrls, setQrcodeUrls] = useState([])

  const onSelectShop = async (shop) => {
    try {
      setIsLoading(true)

      let res = await getItemOption({ shopInfo: shop })
      if (res.err) throw Error(res.err.message)

      const itemOptions2 = parseItemOptions({ shopInfo: shop, itemOptions: res.result.Item })
      if (itemOptions2?.err) throw Error(itemOptions2.err.message)
      if (debug) console.table(itemOptions2.TableList)

      const urls = createQrcodeUrls({ shopInfo: shop, itemOptions2 })
      // const urls = createQrcodeUrls2({ shopInfo: shop, itemOptions2 })
      setQrcodeUrls(urls)
      setShopInfo(shop)
    } catch (e) {
      alert(e.message)
      if (debug) console.log(e)
      setQrcodeUrls([])
    } finally {
      setIsLoading(false)
    }
  }

  const onCopied = (obj) => {
    // if(debug) console.log('copied', obj.url)
    setQrcodeUrls(pre => {
      const newUrls = pre.map(o => {
        if (obj.tableName === o.tableName) o.copied = true
        return o
      })
      return newUrls
    })

    setTimeout(() => {
      setQrcodeUrls(pre => {
        const newUrls = pre.map(o => {
          o.copied = false
          return o
        })
        return newUrls
      })
    }, 1000)
  }

  const qrsize = 100
  const textStyle = { ...gStyle.text, margin: "5px 0px" }
  const styles = {
    wrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: "wrap",
    },
    qrWrap: {
      width: qrsize + 100,
      padding: 10
    },
    text: textStyle,
    uncopied: { ...textStyle, cursor: "pointer" },
    copied: { ...textStyle, color: colors.danger }
  }

  return (
    <Page stretch title={title}>

      <Section bgColor="#fff">
        <SearchStore
          onSelectStore={onSelectShop}
          defaultValue={debug ? "오아시스" : ""}
        />
      </Section>

      {qrcodeUrls.length > 0 &&
        <Section bgColor='#fff'>
          <h2 style={{ ...gStyle.title, ...gStyle.bold }}>{shopInfo.shopName}</h2>

          <br />
          <a href={qrcodeUrls[0].url} target="_blank">{qrcodeUrls[0].url}</a>
          <br /><br />

          <p style={gStyle.subtitle}>테이블 수: {qrcodeUrls.length}</p>
          <div style={styles.wrap}>
            {qrcodeUrls.map((obj, i) => {
              const tableTxt = `${obj.tableNo} - ${obj.tableName}`
              return (
                <div style={styles.qrWrap} key={i}>
                  <p style={styles.text}>{tableTxt}</p>
                  <QRCodeSVG value={obj.url} size={qrsize} />
                  <CopyToClipboard text={obj.url} onCopy={() => onCopied(obj)}>
                    <p style={obj.copied ? styles.copied : styles.uncopied}>
                      {obj.copied ? "Copied" : "Copy URL"}
                    </p>
                  </CopyToClipboard>
                </div>
              )
            })}
          </div>
        </Section>
      }

      {isLoading && <LoaderFullPage />}
    </Page>
  );
}

function createQrcodeUrls({ shopInfo, itemOptions2 }) {

  let tableList = []

  if (shopInfo.posName === "UNIONPOS" || shopInfo.posName === "OKPOS") {
    tableList = itemOptions2.TableList
  }

  if (shopInfo.posName === "OASIS_POS" || shopInfo.posName === "DUMMY_POS") {
    tableList = itemOptions2.tableList
  }

  const urls = tableList.map(tbl => {

    let tableNo = ""
    let tableName = ""

    if (shopInfo.posName === "UNIONPOS" || shopInfo.posName === "OKPOS") {
      tableNo = tbl.TableNo
      tableName = tbl.TableName
    }
    if (shopInfo.posName === "OASIS_POS") {
      tableNo = tbl.tableNo
      tableName = tbl.tableName
    }

    let url = `https://order.taptag.co.kr/v2/${shopInfo.shopId}/${tableNo}`
    return {
      tableNo: tableNo,
      tableName: tableName,
      url: url + "/auth?e=62952B8772D7BBAB80F5E77C28CFD926"
    }
  })
  return urls
}

// 
// auth.onl.taptag.co.kr
//
function createQrcodeUrls2({ shopInfo, itemOptions2 }) {
  const host = authDebug ? "http://172.30.1.27:5010" : "https://auth.onl.taptag.co.kr"
  const urls = itemOptions2.TableList.map(tbl => {
    let url = `${host}/v2/${shopInfo.shopId}/${tbl.TableNo}`
    return {
      tableNo: tbl.TableNo,
      tableName: tbl.TableName,
      // url: url + "/auth?e=62952B8772D7BBAB80F5E77C28CFD926"
      url
    }
  })
  return urls
}