import React, { useEffect, useState } from "react";
import { Jumbotron } from "./Jumbotron2";
// import apis from "../../lib/apis-dbtables";
import { SectionTextImage, SectionVideo, SectionShopItems } from "./Sections";
import { exampleData } from "./example-data2";
import { json2obj } from "../../../lib/utils";
import { getPost } from "../../../lib/ec2-api-cms2-lib";
import qs from "qs"

export default function ViewLandingPage(props) {

  // const pageContents = exampleData


  /** @TODO header와 section의 데이터 구조를 동일하게 할 것*/

  /** 
   * @TODO 
   *  page로 이동하고 
   *  admin일 경우 wrapper에 각 component를 포함하여 wrapper 에서 편집 기능을 추가할 것
   *  page pageContents 도 마찬가지
   */

  /** 
   * @TODO pageContents table 생성 | 저장 | 편집 
   * pkey: catetory
   * skey: cid
   * groupName, categoryName, title,
   * 
   * @오늘_cms_구조_참조할것
   */

  const [post, setPost] = useState({})
  const [pageContent, setPageContent] = useState([])

  useEffect(() => {
    onload()
  }, [])

  const onload = async () => {
    try {
      const q = qs.parse(window.location.search.split('?')[1]);
      if (q.categoryId && q.postId) {
        let res = await getPost(q);
        if (res.err) throw Error(res.err.message)
        const p = res.result.Item
        setPost(p)

        const pContent = json2obj(p.pageContent)
        setPageContent(pContent)
      }
    } catch (e) {
      alert(e.message)
    }
  }

  /** 
   * @TODO customContent 를 pageConten 사이에 넣을 수 있는 방법 강구
   * 
   */

  const customContentPosition = 2

  return (
    <>
      {
        pageContent.map((c, i) => {

          if (c.name === "header" && c.type === "jumbotron") {
            return <Jumbotron key={"jumbo" + i} {...props} data={c} />
          }

          if (c.name === "section") {
            if (c.type === "text-image") {
              return <SectionTextImage key={"section" + i} {...props} data={c} />
            }

            if (c.type === "video") {
              return <SectionVideo key={"section" + i} {...props} data={c} />
            }
          }

          // return null
        })
      }

      {/**@customContent */}
      {/* {items.length > 0 &&
        <SectionShopItems
        {...props}  
          data={{
            title: "신규 런칭 서비스 | 상품",
            items
          }}
        />} */}

    </>
  );
}