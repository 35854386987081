import imageCompression from "browser-image-compression";
import { debug } from "../settings";

// export const AuthContext = React.createContext();

export function parseTitle(html) {
  let start = html.indexOf("<h1>");
  console.log(start, html);
  if (start > -1) {
    let end = html.indexOf("</h1>");
    return html.slice(start + 4, end);
  } else return null;
}

export function dec2hex(n) {
  n = parseInt(n);
  var c = "ABCDEF";
  var b = n / 16;
  var r = n % 16;
  b = b - r / 16;
  b = b >= 0 && b <= 9 ? b : c.charAt(b - 10);
  return r >= 0 && r <= 9 ? b + "" + r : b + "" + c.charAt(r - 10);
}

export function logObj(obj) {
  if (debug) console.log(JSON.stringify(obj, null, 2));
}

const clone = (obj) => Object.assign({}, obj);

export function renameKey(obj, key, newKey) {
  const clonedObj = clone(obj);
  const targetKey = clonedObj[key];
  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
}

export const objArr2str = (objArr) => {
  const arr = objArr;
  let list = [];
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i];
    if (typeof item === "object") list.push(Object.keys(item)[0]);
  }
  return list.join(", ");
};

export const str2objArr = (str) => {
  let list = [];
  let arr = str.split(",");
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i].trim();
    let obj = {};
    obj[item] = "";
    list.push(obj);
  }
  return list;
};

export const str2arr = (str) => {
  let list = [];
  let arr = str.split(",");
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i].trim();
    list.push(item);
  }
  return list;
};

export function capitalize(str) {
  const newStr = str.replace(/^\w/, (c) => c.toUpperCase());
  return newStr;
}

export function hex2int(hexString) {
  let hex = hexString.toString();
  let Uint8Array = [];
  for (let n = 0; n < hex.length; n += 2) {
    Uint8Array.push(parseInt(hex.substr(n, 2), 16));
  }
  return Uint8Array;
}

// export function rand(num) {
//   const arr = [];
//   for (let i = 0; i < num; i++) {
//     arr.push(Math.floor(Math.random() * 256));
//   }
//   return arr;
// }

export function rotateRight(arr) {
  let last = arr.pop();
  arr.unshift(last);
  return arr;
}

/**
 * 
 * @param {*} props.inputFields db.sckima 정의
 * @param {*} props.editItems db.sckima 정의
 * @returns 
 */
export function createFields({ inputFields, editItems }) {
  let fields = {};
  const isEdit = (editItems !== null) && (editItems !== undefined) && Object.keys(editItems).length > 0;

  for (let i = 0; i < inputFields.length; i++) {
    let item = inputFields[i];
    if (isEdit) {
      fields[item.name] = editItems[item.name];
    } else {
      if (item.inputType === "checkbox") fields[item.name] = item.defaultValue || false;
      else if (item.inputType === "select") fields[item.name] = item.defaultValue || item.selectList[0] || "";
      else fields[item.name] = item.defaultValue || "";
    }
  }
  // if(debug) console.log('fields:', fields)
  return fields;
}

// /**
//  *
//  * @Description Input form을 자동 생성하기 위한 함수
//  *
//  * @param {Array} param.inputFields: settings.js 에서 온 db fields
//  * @param {Object} param.items: 서버로부터 입수한 data object
//  * @returns {Object}
//  *
//  * items 가 undefined 이면 inputFields 내용으로만 return obj를 구성함.
//  */
// export function getObjFromInputFields({ inputFields, items }) {
//   let obj = {};
//   for (let i = 0; i < inputFields.length; i++) {
//     let item = inputFields[i];
//     if (items) {
//       obj[item.name] = items[item.name];
//     } else {
//       obj[item.name] = item.defaultValue || "";
//     }
//   }
//   return obj;
// }

// export function log() {
//   if (!debug) return;
//   const args = Array.prototype.slice.call(arguments, 0);
//   let logStr = "";
//   for (let i = 0; i < args.length; i++) {
//     logStr += args[i];
//     if (i < args.length - 1) logStr += " ";
//   }
//   // console.log(args);
//   console.log(logStr);
// }

/**
 *
 * @param {String} querystring location.search
 * @returns {Object}
 */
export const queryString2Obj = (querystring) => {
  const params = new URLSearchParams(querystring);
  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

/**
 * @Info check browser information
 * @returns {Object} {isMobileDevice, details}
 */
export const checkBrowser = () => {
  /* Storing user's device details in a variable*/
  let details = navigator.userAgent;

  /* Creating a regular expression 
  containing some mobile devices keywords 
  to search it in details string*/
  let regexp = /android|iphone|kindle|ipad/i;

  /* Using test() method to search regexp in details
  it returns boolean value*/
  let isMobileDevice = regexp.test(details);
  return { isMobileDevice, details };
};

export const getMobileOsType = (details) => {
  const types = ["Android", "iOS", "Windows NT"];
  let type = null;
  types.forEach((item) => {
    if (details.indexOf(item) > 0) type = item;
  });
  return type;
};

/**
 * @deprecated
 * 
 * @param {*} email 
 * @returns 
 */
export function validateEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function validatePhoneNo(phoneNoString) {
  if (phoneNoString) {
    // const mobileNo10 = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // const mobileNo11 = /^\(?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

    const mobileNo10 = /^\(?([0-9]{3})\)?([0-9]{3})?([0-9]{4})$/;
    const mobileNo11 = /^\(?([0-9]{3})\)?([0-9]{4})?([0-9]{4})$/;
    // const mobileNoFull = /^\(?([0-9]{3})\)?([0-9]{4})?([0-9]{4})$/;

    if (phoneNoString.match(mobileNo10) || phoneNoString.match(mobileNo11)) return true;
    return false;
  } else return false;
}

/**
 * @deprecated
 * 
 * @param {*} password 
 * @returns 
 */
export function validatePassword(password) {
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
  return passwordRegex.test(password);
  // if (!passwordRegex.test(password))
  //   return {
  //     isPassword: false,
  //     message: '8~25자리 영문자, 숫자, 특수문자 조합',
  //   }
  // else
  //   return {
  //     isPassword: true,
  //     message: '올바른 비밀번호 형식 입니다.',
  //   }
}

/**
 * @deprecated
 * 
 * @param {*} item inputFields item
 *            check item.required & item.validate
 * @param {*} value state item.value
 * @returns
 */
export const validateInputValue = (item, value) => {
  //
  // required=false 항목은 무조건 validate 필요없음
  //
  if (item.required === false) {
    // console.log('item not required', item.name)
    return true;
  }

  //
  // validate 지정이 된 것은 type 에 따라 validate
  // validate 지정이 안되이 있으면 required 에 따른 공란만 체크함.
  //
  if (item.validate) {
    const type = item.validate.type;
    if (type === "email") return validateEmail(value);
    if (type === "password") return validatePassword(value);
    if (type === "phone_number") return validatePhoneNo(value);
  } else {
    if (value !== "") return true;
  }

  return false;
};

export const isJson = (str) => {
  try {
    var json = JSON.parse(str);
    return typeof json === "object";
  } catch (e) {
    return false;
  }
};
export const json2obj = (json, type = "array") => {
  if (isJson(json)) {
    return JSON.parse(json)
  }
  else {
    if (type === "array") return []
    else return {}
  }
}

/**
 * @used_ib_Update_API
 * 
 * @param {*} originalItems 
 * @param {*} newItems 
 * @returns 
 */
export const bodyToUpdate = (originalItems, newItems) => {
  let body = {};
  Object.entries(newItems).forEach(([key, value]) => {
    if (originalItems[key] !== value) body[key] = value;
  });
  return body;
};

export function getTimestampFromNow({ now, days = 1, hours = 0, minutes = 0, seconds = 0, milliseconds = 0 }) {
  now.setDate(now.getDate() + days);
  now.setHours(hours)
  now.setMinutes(minutes)
  now.setSeconds(seconds)
  now.setMilliseconds(milliseconds)
  return new Date(now).getTime();
}

/**
 * 
 * @param {*} param.now date Object
 * @param {*} param.type 
 *              begin : 시작 시간 0시
 *              end   : 마지믹 시간 23시 59분 59초 999밀리초
 * @returns 
 */
export function getTimestamp({ now, type = "begin", days = 0 }) {
  now.setDate(now.getDate() - days);
  now.setHours(type === "begin" ? 0 : 23)
  now.setMinutes(type === "begin" ? 0 : 59)
  now.setSeconds(type === "begin" ? 0 : 59)
  now.setMilliseconds(type === "begin" ? 0 : 999)
  return new Date(now).getTime();
}

export async function imageCompress({ image, maxSizeMB = 1, maxWidthOrHeight = 1920 }) {
  const file = image;
  if (debug) console.log('original size: ', file.size / 1000, "k bytes");
  const options = {
    maxSizeMB, maxWidthOrHeight,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(file, options);
    if (debug) console.log('compressed size: ', compressedFile.size / 1000, "k bytes");
    return compressedFile;
  } catch (e) {
    if (debug) console.log('file resizing Error: ', e);
    return null;
  }
}

export function log(msg, data = "") {
  if (debug) {
    const n = 20;
    let spaceLen = msg.length >= n ? msg : n - msg.length;
    const _msg = msg + ' '.repeat(spaceLen);
    if (Buffer.isBuffer(data)) console.log(_msg, numArray2hexStr(data));
    else if (typeof data === 'object') console.log(_msg, JSON.stringify(data));
    else console.log(_msg, data);
  }
}

/**
 * 
 * @param {Buffer | ByteArray} arr 
 * @returns String
 */
export function numArray2hexStr(arr) {
  let str = "";
  arr.map(num => str += num.toString(16).toUpperCase().padStart(2, '0'));
  return str;
}

/**
 * 
 * @param {String} hexStr 
 * @returns ByteArray
 */
export function hexStr2NumArray(hexStr) {
  const hexArr = hexStr.match(/.{1,2}/g);
  return hexArr.map(item => parseInt(item, 16));
}

export function delay(ms) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), ms)
  });
}

/**
 * 
 * @param {Array} param.arr
 * @param {String} param.key
 * @param {String} param.type up | down
 * @returns 
 */
export function sortObjArray({ arr, key, type = "up" }) {
  const newArr = [...arr]
  newArr.sort((a, b) => {
    let condition = true
    if (type === "up") condition = (a[key] - b[key]) >= 0
    else condition = (a[key] - b[key]) <= 0

    if (condition) return 1
    return -1
  })
  return newArr
}

export function sortObjArray2({ arr, key, type = "up" }) {
  const newArr = [...arr]
  newArr.sort((a, b) => {
    if(type==="up") {
      if(a[key] >= b[key]) return 1
      else return -1
    } else {
      if(a[key] >= b[key]) return -1
      else return 1
    }
    // return 0
  })
  return newArr
}

export const getTnUrl = (imgUrl) => {
  if (imgUrl === "") return ""
  const urlObj = new URL(imgUrl)
  const arr = urlObj.pathname.split('/')
  arr.splice(arr.length - 1, 0, 'tn')
  return urlObj.origin + arr.join('/')
}

/**
 * @deprecated use getPriceStr
 * @param {*} text 
 * @returns 
 */
export function kiloComma(text) {
  return text
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getPriceStr(price) {
  const newPrc = parseInt(price)
  if (isNaN(newPrc)) return "0"
  return kiloComma(newPrc)
}

export const cryptoRandom = (length = 32) => {
  const characters = "ABCDEF0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    const loc = Math.floor(Math.random() * charactersLength);
    result += characters.charAt(loc);
  }
  return result;
};

export const tsId = () => {
  const tsShort = Math.floor(Date.now()/1000)
  return tsShort.toString() + cryptoRandom(10)
};
