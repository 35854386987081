// import { posList } from "./posList";
import { isJson } from "../lib/utils";

const pos = {
  "UNIONPOS": { parseItemOptions: parseItemOptionsUnionpos },
  "OKPOS": { parseItemOptions: parseItemOptionsOkpos },
  "DUMMY_POS": { parseItemOptions: parseItemOptionsDummypos },
  "OASIS_POS": { parseItemOptions: parseItemOptionsOasispos },
}

const posList = Object.keys(pos)


export function parseItemOptions({ shopInfo, itemOptions }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  return pos[shopInfo.posName].parseItemOptions({ itemOptions });
}

function parseItemOptionsUnionpos({ itemOptions }) {
  return {
    ...itemOptions,
    GrpList: isJson(itemOptions.GrpList) ? JSON.parse(itemOptions.GrpList) : [],
    MidGrpList: isJson(itemOptions.MidGrpList) ? JSON.parse(itemOptions.MidGrpList) : [],
    SetMenuList: isJson(itemOptions.SetMenuList) ? JSON.parse(itemOptions.SetMenuList) : [],
    ChoiceMenuList: isJson(itemOptions.ChoiceMenuList) ? JSON.parse(itemOptions.ChoiceMenuList) : [],
    TableList: isJson(itemOptions.TableList) ? JSON.parse(itemOptions.TableList) : [],
    menuSettings: isJson(itemOptions.menuSettings) ? JSON.parse(itemOptions.menuSettings) : [],
    tableCallList: isJson(itemOptions.tableCallList) ? JSON.parse(itemOptions.tableCallList) : [],
  }
}

function parseItemOptionsOkpos({ itemOptions }) {
  const names = [
    "TuClsList",
    "GrpList", "MidGrpList", "SmGrpList",
    "SdaCdList", "SdaClsList",
    "SdsCdList", "SdsClsList", "SdsGrList",
    "TableList", "menuSettings", "tableCallList"
  ]
  const itemOptions2 = {}
  names.map(name => {
    itemOptions2[name] = isJson(itemOptions[name]) ? JSON.parse(itemOptions[name]) : []
  })
  return itemOptions2
}

function parseItemOptionsDummypos({ itemOptions }) {
  const names = [
    "groupList",
    "optionGroupList", "optionClassList", "optionItemList",
    "tableList", "menuSettings", "tableCallList",
  ]
  const itemOptions2 = { ...itemOptions }
  names.map(name => {
    itemOptions2[name] = isJson(itemOptions[name]) ? JSON.parse(itemOptions[name])
      : (name === "menuSettings") ? {} : []
  })
  return itemOptions2
}

function parseItemOptionsOasispos({ itemOptions }) {
  const names = [
    "groupList",
    "setMenuList",
    "tableList", "menuSettings", "tableCallList",
  ]
  const itemOptions2 = { ...itemOptions }
  names.map(name => {
    itemOptions2[name] = isJson(itemOptions[name]) ? JSON.parse(itemOptions[name])
      : (name === "menuSettings") ? {} : []
  })

  const groupList = itemOptions2.groupList.map(g=>{
    return {
      ...g,
      name: g.groupName,
      GrpCode: g.groupCode,
      GrpName: g.groupName,
    }
  })

  itemOptions2.groupList = groupList

  return itemOptions2
}