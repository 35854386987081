import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button } from "../../../components2";
import { useStyles } from "../../../components2";
import { ArrowL } from "../../../components2/Icons";

export function Jumbotron(props) {
  const { data } = props
  const { gStyle, colors } = useStyles()
  const { isMobilePhone } = useSelector(state => state.platform)
  // const navigate = useNavigate()
  // const navigate = props.history.push

  const [isHovered, setIsHovered] = useState(false)

  const onClickLink = (url) => {
    if (url.includes("http")) window.location.replace(url)
    // else navigate(url)
    else props.history.push(url)
  }

  const bgImg = `url(${data.images[0].url})`

  const styles = {
    jumboWrap: {
      // width: "100%",
      backgroundImage: bgImg,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",

      // borderRadius: "0px",
      // position: "relative",
      // zIndex: 0,

      // minHeight: "40vh",
      // display: "flex", alignItems: "center"
    },
    contentWrap: {
      width: "100%",
      minHeight: "40vh",
      padding: isMobilePhone ? "50px 10px" : "50px 30px",
      textAlign: isMobilePhone ? "left" : "left",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.3)" // overlay mask
    },
    title: {
      ...gStyle.title,
      fontSize: isMobilePhone ? "26px" : "40px",
      color: "#fff",
      marginBottom: 30
    },
    subtitle: { ...gStyle.subtitle, color: "#fff", lineHeight: "2.0rem", fontWeight: "500" },
    btnWrap: {
      marginTop: 30,
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap"
    },
    btn: {
      width: "200px",
      borderRadius: "10px",
      marginRight: "20px",
      marginBottom: "10px"
    }
  }

  return (
    <div style={styles.jumboWrap}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >

      <div style={styles.contentWrap}>
        <Container>

          <HiddenBtn {...props} isHovered={isHovered} color="#fff" onClick={() => props.history.goBack()} />

          <h1 style={styles.title}>{data.title}</h1>
          {data.texts &&
            data.texts.map((t, i) => {
              return (
                <p
                  key={"text" + i}
                  style={{
                    ...styles.subtitle,
                    margin: "0.5rem 0px",
                    color: colors[t.variant] || "#fff",
                    display: t.display || "block"
                  }}
                >
                  {t.value}
                </p>
              )

            })
          }

          {data.links &&
            <div style={styles.btnWrap}>
              {
                data.links.map((link, i) => {
                  return (
                    <Button
                      key={"link" + i}
                      variant={link.variant}
                      style={styles.btn}
                      title={link.text}
                      onClick={() => onClickLink(link.linkUrl)}
                    />
                  )
                })
              }
            </div>
          }
        </Container>

      </div>
    </div>
  )
}

function HiddenBtn(props) {
  const { gStyle } = useStyles()

  // const [isHovered, setIsHovered] = useState(false)

  return (
    // <div
    // // onMouseEnter={() => setIsHovered(true)}
    // // onMouseLeave={() => setIsHovered(false)}
    // >
      <ArrowL
        style={{
          ...gStyle.subtitle,
          color: props.isHovered ? props.color : "transparent",
          fontSize: "2rem",
        }}
        onClick={props.onClick}
      />
    
  )
}
