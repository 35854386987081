import React, { useState, useEffect } from "react";
import { Page, Section, LoaderFullPage } from "../../components";
import {
  Button, PeriodWithCalendar,
  // CustomTable,
} from "../../components2";
import { CustomTable } from "../../components2/Tables2";
import { shopListByPeriod } from "../../lib/ec2-api-lib";
import { getTimestamp } from "../../lib/utils";
import { debug } from "../../settings";

const shopTableHeader = [
  { name: "created", displayName: "등록일", sorting: true },
  { name: "shopName", displayName: "가맹점", sorting: true },
  // { name: "shopId", displayName: "가맹점 아이디" },
  { name: "posName", displayName: "포스", sorting: true },
  { name: "orderCount", displayName: "누적주문", type: "number", sorting: true },
  { name: "orderCountAvr", displayName: "일평균", type: "number", sorting: true },
]

export default function NewShops(props) {

  const [isLoading, setIsLoading] = useState(false);

  const defaultDays = 30;
  const today = new Date()
  const _startDate = new Date(getTimestamp({ now: today, days: (defaultDays - 1) }))
  const _endDate = new Date(getTimestamp({ now: new Date(), type: "end" }))
  const [sDate, setSDate] = useState(_startDate)
  const [eDate, setEDate] = useState(_endDate)

  const [shopList, setShopList] = useState([])
  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(0)
  const [days, setDays] = useState(defaultDays)

  const [tHeader, setTHeader] = useState([])

  useEffect(() => {

    loadStats({ startDate: sDate, endDate: eDate })
  }, [sDate, eDate])

  const loadStats = async ({ startDate, endDate }) => {
    try {
      setIsLoading(true)

      // 
      // getShopList 
      // 
      let res = await getValidShopList({ startDate, endDate })
      if (res.err) throw Error(res.err.message);
      // console.log(res.validShopList)
      const shops = [...res.validShopList]
      shops.sort((a, b) => {
        // if ((a.created - b.created) <= 0) return 1
        if ((a.orderCount - b.orderCount) <= 0) return 1
        return -1
      })

      setShopList(shops)
      setStartTime(res.start_time)
      setEndTime(res.end_time)

    } catch (e) {
      alert(e.message)
      if (debug) console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const onClick = async () => {
    try {
      setShopList([])
      const tsDiff = new Date(eDate).getTime() - new Date(sDate).getTime()
      const oneDayTs = 1000 * 60 * 60 * 24
      const daysDiff = Math.floor(tsDiff / oneDayTs)
      setDays(daysDiff)
      loadStats({ startDate: sDate, endDate: eDate })
    } catch (e) {
      alert(e.message)
      console.log(e)
    }
  }

  const onClickItem = async (shopInfo) => {
    props.history.push({
      pathname: "/store/order-check",
      state: {
        shopInfo,
        startDate: new Date(getTimestamp({ now: new Date(), days: 6 })),
        endDate: new Date(endTime)
      }
    })
  }

  const styles = {
    calWrap: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    text: { margin: 3 }
  }

  return (
    <>
      <Section bgColor="#fff">
        {/* <Button
          size="sm"
          // width="300px"
          variant="outline-secondary"
          onClick={onClick}>Reload</Button>
        <br /> */}
        <PeriodWithCalendar
          startDate={sDate}
          setStartDate={setSDate}
          endDate={eDate}
          setEndDate={setEDate}
        />

      </Section>
      {
        shopList.length > 0 &&
        <Section bgColor="#fff">
          <h2> {days}일간 신규 가맹점 주문 통계 </h2>
          <h5 style={styles.text}>가맹점 수:&nbsp;&nbsp;{shopList.length}</h5>
          <h5 style={styles.text}>
            조회 기간:&nbsp;&nbsp;
            {new Date(sDate).toLocaleDateString()}
            &nbsp;&nbsp;~&nbsp;&nbsp;
            {new Date(eDate).toLocaleDateString()}
          </h5>
          <CustomTable
            {...props}
            tableHeader={shopTableHeader}
            rowItems={shopList}
            setRowItems={setShopList}
            onClickItem={onClickItem}
          />
        </Section>
      }

      {isLoading && <LoaderFullPage />}
    </>
  );
}

function excluded(shopId) {
  const excludeList = [
    'f064e7c311cc8f66a4a7', // no name, no user
    '8c3ee052b714a2189fc3', // no name, user= gozip132
    '39304864c81ac63b77bb', // no name, no user
    'd44b28cf7e78eac9acbe', // no 레코드, no user
    '888257dd3f6eaa1bed8b', // 공사중(샘플매장)
    '714060b71fc10cc3d184', // 현대포스
    '0f0e027131122f8e6b57', // 현대포스

    /** PSDL DUMMY_POS */
    '43a047780a6478c1c9c4', // PSDL 레스토랑 - DUMMY

    /** PSDL UNION_POS */
    'a59a4c9e870ba5b129ef', // PSDL 레스토랑
    '150f76e017dcc60a51d9', // 티알디 psdltest
    'd6a1a7701a1d09f7c353', // psdltest2
    '3af23262d155ff09b77f', // PSDL 테스트 점
    'fa5ddf7c1ed4eb4b2ac1', // PSDL 레스토랑 - TESTSHOP
    '64f55910d026ca554dee', // PSDL 테스트 점 마왕족발
    'bebb1dcd80e121a8a752', // 청년다방 테스트

    /** PSDL OKPOS */
    'c9adf8b8bc9667a8889f', // OKpos test
    'b52a7c0d325aa271f529', // PSDL OKPOS 테스트샵

    /** 라이트 파트너스 테스트 샵 */
    '64d60cca79c662daa843', // 오늘 떡볶이 부천중동점
    '54d47dfc158d74e50995', // 오늘 떡볶이 여의도점
    '836ca18df563bf5d3812', // 오늘 떡볶이 서현점
    'c847292fa8829d3f5a6f', // 오늘 떡볶이 선릉점
    'a47d4c3e30e1b5db373e', // 오늘 떡볶이 판교점
    'fd5ad1465f8b8e9c1450', // 오늘 떡볶이
    '02ffe118b579e11701cd', // 오늘 떡볶이 마곡점
    '51dcc0e815c30b02842f', // 오늘 떡볶이 부천상동점
    '72d7001192a091fe0672', // OKPOS 본사 테스트용
    '0dd6d2d398af88c75dcb',
    '80fab857baf4fe252aa7',
    '289b42d74cbbea659a8c',
    '360f505f359d2fd2bec0',
    '2ff1513ed623c31e5fa4',

  ]
  return excludeList.includes(shopId);
}

async function getValidShopList({ endDate, startDate }) {
  let shops = [];

  let end_time = getTimestamp({ now: endDate, type: "end" })
  let start_time = getTimestamp({ now: startDate })

  // console.log('end', end_time, new Date(end_time).toLocaleString())
  // console.log('end', start_time, new Date(start_time).toLocaleString())

  let res = await shopListByPeriod({ start_time, end_time });
  if (res.err) return { err: { message: res.err.message } };
  shops = res.result.Items;

  const validShopList = []
  shops.map(shop => {
    if (
      shop.shopName !== undefined &&
      !excluded(shop.shopId) &&
      shop.orderCount
    ) validShopList.push(shop);
    return null;
  })

  const newShops = validShopList.map(shop => {
    const tsDiff = Date.now() - shop.created
    const oneDayTs = 1000 * 60 * 60 * 24
    const daysDiff = Math.floor(tsDiff / oneDayTs) || 1
    const newShop = {
      ...shop,
      orderCountAvr: shop.orderCount ? Math.floor(shop.orderCount / daysDiff) : 0
    }
    return newShop
  })

  return { validShopList: newShops, start_time, end_time };
}
